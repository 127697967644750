
















































import { Component, Vue } from 'vue-property-decorator';
import { dispatchCreateResponsavel, dispatchGetResponsaveis } from '@/store/responsavel/actions';
import { IResponsavelCreate } from '@/interfaces/responsavel';

@Component
export default class CadastrarResponsavel extends Vue {
  public valid = false;
  public nome: string = '';
  public sigla: string = '';
  public porLocalidade: boolean = false;
  public porPontoAcesso: boolean = false;

  public async mounted() {
    await dispatchGetResponsaveis(this.$store);
    this.reset();
  }

  public reset() {
    this.nome = '';
    this.sigla = '';
    this.porLocalidade = false;
    this.porPontoAcesso = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const novoResponsavel: IResponsavelCreate = {
        nome: this.nome,
        sigla: this.sigla,
        por_localidade: this.porLocalidade,
        por_ponto_acesso: this.porPontoAcesso,
      };
      const cadastrou = await dispatchCreateResponsavel(this.$store, novoResponsavel);
      if (cadastrou) {
        this.$router.push('/main/responsavel/listar');
      }
    }
  }
}
